	// ############## 
	//	Directives // These can be removed + reused
	// ##############
	angular.module('mx.directives', [])
	// -------------------------------------------------------
	// Make things that aren't links look and be  clickable
	// -------------------------------------------------------
	.directive('mxClickable', [function(){
		return {
	    require: '?ngModel',
			link : function($scope, element , attrs){
				$(element).addClass('mx-clickable');
				$(element).on('click', function(){
					window.location.href = attrs.mxClickable
				})
			}
		}
	}])

	// -------------------------------------------------------
	// -- create a blank html element that won't inherit any styles
	// -------------------------------------------------------
	.directive('mxDiv', [function(){
		return {
			link : function(){ }
		}
	}])

	// -------------------------------------------------------
	// -- Takes the value supplied by mxValue
	// -------------------------------------------------------
	.directive('mxValue', ['$parse', function($parse){
		return {
	    link: function (scope, element, attrs) {
	      if (attrs.ngModel && attrs.value) {
	        $parse(attrs.ngModel).assign(scope, attrs.value);
	      }else{
		    	if (attrs.ngModel && attrs.mxValue) {
		        $parse(attrs.ngModel).assign(scope, attrs.mxValue);
		      }
		    }
	    }
		}
	}])

	// -------------------------------------------------------
	// -- Takes the value supplied by mxValue
	// -------------------------------------------------------
	.directive('mxLazySrc', ['$parse', function($parse){
		return {
	    link: function (scope, element, attrs) {
		    $(element).src = attrs.mxLazySrc
	    }
		}
	}])


	// -------------------------------------------------------
	// Takes a config object to initialize the owl carousel
	// -------------------------------------------------------
	.directive('mxOwl', [function(){
		return {
			controller : ['$scope', '$element', '$attrs', function($scope, $element, $attrs){
				this.config = $scope.carousel_config;
				this.element = $element;
			}]
		}
	}])
	// -------------------------------------------------------
	// Looks at the repeat items
	// to see when the last one has rendered then 
	// initialize the owl carousel from the config and the element in the parent 
	// -------------------------------------------------------
	.directive('mxOwlItem', [function(){
		return {
			require : '^mxOwl',
			link : function(scope, element, attrs , mxOwl){
				if(scope.$last){
					$(mxOwl.element).owlCarousel(mxOwl.config)
				}
			}
		}
	}])
	// -------------------------------------------------------
	// -- Moves the modal ... or anything to just before the body tag
	// -------------------------------------------------------
	.directive('mxMoveModal', [function(){
		return {
			link : function($scope, element , attrs){
				$(element).appendTo('body');
			}
		}
	}])
	
	// -------------------------------------------------------
	// -- Moves the modal ... or anything to just before the body tag
	// -------------------------------------------------------
	.directive('mxPrint', [function(){
		return {
			link : function($scope, element , attrs){
				$(element).css('cursor', 'pointer');
				$(element).on('click', function(){
					window.print();
				})
			}
		}
	}])
	
	// -------------------------------------------------------
	// -- Jump to a category page
	// -------------------------------------------------------
	.directive('mxCategoryJumpList', [function(){
		return {
			scope : { categories : '=categories'  , category : '=category' , blogUrl :"@blogUrl"},
			link : function( $scope, $element, $attrs  ){ }, // link
			template : function ( scope, element ){ 
				return '<select  ng-options="o as o.name for o in $ctrl.categories" ng-model="$ctrl.category" ng-change="$ctrl.jump( cat.link )">\
									<option value ="" selected="selected">Category</option>\
								</select>';
			}, // template 
			controller : function($scope, $http, $timeout, $attrs ){
				var $ctrl = $scope.$ctrl = this;
				$ctrl.$onInit = function(){
					$ctrl.blogUrl = typeof( $scope.blogUrl ) !== 'undefined' ? $scope.blogUrl : '/blog/';
					$ctrl.categories = $scope.categories;
					$ctrl.category = _.find( $ctrl.categories, function( $cat ){
						 return $scope.category.term_id == $cat.term_id;
					})
					
				}
				$ctrl.jump = function(){
					if( $ctrl.category == null ) window.location.href = $ctrl.blogUrl
					window.location.href = $ctrl.category.link;
				}

			}
		}
	}])	
	
	
	
	// -------------------------------------------------------
	// -- load more posts 
	// -------------------------------------------------------
	.directive( 'mxLoadMore', ['$http', '$timeout', function( $http, $timeout ){
		return {
			scope : { params :"=params" , container :"@container" , btnClass : "@btnClass" , btnLabel : "@btnLabel"},
			template : function($scope, $element, $attrs ){
					return '<button type = "button" class ="{{ $ctrl.btn_class}}"\
						ng-click="$ctrl.loadMore()" ng-hide="$ctrl.params.current_page == $ctrl.params.max_page">\
						<span class = "fa fa-spin fa-refresh" ng-show="$ctrl.loading"></span>\
						{{ $ctrl.btn_label}}\
					</button>';
			},
			controller : function( $scope, $element, $attrs ){

				var $ctrl = $scope.$ctrl = this;
				
				$ctrl.$onInit = function(){
					$ctrl.params = $scope.params;
					$ctrl.container = $scope.container;
					$ctrl.loading = false;
					$ctrl.btn_class = typeof( $scope.btnClass ) !== 'undefined' ? $scope.btnClass : 'btn btn-default';
					$ctrl.btn_label = typeof( $scope.btnLabel ) !== 'undefined' ? $scope.btnLabel : 'Load More';
				}
				
				$ctrl.loadMore = function(){
					$ctrl.loading = true;
					var $data = $ctrl.params;
					$http.post( '/?blog::load_more' , $data ).then( function( response ){
						if( response.data.count > 0 ){
							$('#posts-container').append( response.data.content );
							$ctrl.params.current_page ++;
						}
					})
				}
				

			}
		}
		
	}]);
	
	
	


;// end of directives